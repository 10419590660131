
import { defineComponent } from "vue";
import KycConfirmationIndexList from "@/components/page/kyc/kyc-confirmation/IndexList.vue"

export default defineComponent({
  name: "kyc-confirmation-legal-entity",
  components: {
    KycConfirmationIndexList,
  },
  setup() {
    return {};
  },
});
